a#reload_href {
  font-size: 10px;
  position: relative;
  top: -22px;
}

canvas#canv {
  margin-top: 10px;
  padding: 0px !important;
  margin-left: -16px;
  position: relative;
  left: 0px;
}

.cleartbn {
  padding: 10px 20px !important;
  background: #007c7c;
  margin-top: 0px !important;
  font-weight: 400;
  border-radius: 10px !important;
}
.regbghistory {
  background: #919eab1c;
  padding: 20px 15px;
  border-radius: 10px;
  border: 1px solid #9999991f;
  background-color: #fff;
  border-radius: 0px;
  border-top: solid 2px #007c7c;
}

.makeStyles-borderbotmten-23 {
  border-bottom: none !important;
  margin-bottom: 0px !important;
}
table.MuiTable-root.css-163aysv-MuiTable-root tr:nth-child(even) {
  background-color: #fff;
}
.css-aqci5x-MuiButtonBase-root-MuiButton-root {
  box-shadow: none !important;
}

.btn_startrecoding {
  font-weight: 400;
  border-radius: 10px;
  padding: 0px 30px;
  font-size: 14px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-115nxye-MuiInputBase-root-MuiOutlinedInput-root {
  height: 50px;
  background-color: #fff;
}
.setouter {
  width: 100%;
  float: left;
}

@media print {
  div.print-content article.node .node-blog .clearfix div.item-body p a {
    display: none;
  }
}

@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}

.Toastify__progress-bar--info {
  background: #007c7c73 !important;
}
.Toastify__toast-icon svg {
  fill: #007c7c !important;
}
table tr td p {
  line-height: 1.5rem;
  margin-bottom: 10px;
}
.mb-3 {
  margin-bottom: 30px;
}
.btn-danger {
  color: #df3232;
  border: 1px solid #df3232;
}
.btn-danger:hover {
  border: 1px solid #df3232;
}
.f-12 {
  font-size: 12px !important;
}

label.f-12 span.MuiTypography-root {
  font-size: 12px !important;
}
.f-10 {
  font-size: 10px !important;
}

.f-14 {
  font-size: 14px !important;
}
.f-16 {
  font-size: 16px !important;
}
.usexpsol {
  text-decoration: none;
}
.orbox {
  padding: 15px;
}

.searchbtn {
  padding: 10px;
}
.pl-0 {
  padding-left: 0px !important;
}
.containerInfo {
  display: flex;
  width: 100%;
  padding: 20px;
}
.back-btn {
  background: #000000b3;
}
.rejact-btn {
  background: #bc0000c2;
}

.container-btn {
  margin: 10px 0 20px 0;
}
.mt-20 {
  margin-top: 20px !important;
}
.uppercase {
  text-transform: uppercase;
}

.uppercase input {
  text-transform: uppercase;
}

.bg_white_login div {
  padding: 0px 0;
}
.wt-bg input,
.wt-bg .MuiSelect-select,
.wt-bg .MuiInputBase-adornedEnd,
.wt-bg.textarea .MuiInputBase-multiline {
  background: #fff;
  border-radius: 10px;
}
span.moreText {
  margin-left: 18px;
  margin-top: 20px;
}
.f-12 textarea {
  font-size: 12px;
}
.heightwidth {
  width: 100%;
  min-width: 500px;
  height: 500px;
  min-height: 300px;
  overflow: auto;
}
.filterIcon {
  background: none !important;
  color: #007c7c;
  font-size: 30px;
  padding: 0;
  margin: 0;
  width: auto;
  height: auto;
  min-width: fit-content;
  min-height: fit-content;
  line-height: 0;
  border: none;
}

@media (max-width: 767px) {
  .filterIcon {
    float: right;
    margin-top: 10px;
  }

  .options-container div {
    width:100% !important;
    
   }

}
.promotionLabel {
  font-size: 1.5rem;
}
.seprater {
  margin-bottom: 8px;
  margin-top: 10px;
  width: 100%;
}

.tableHeader {
  display: table-header-group;
  background: #eae3e3 !important;
}
.tableHeader tr th {
  color: #3c3838 !important;
}

.form-select:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
.quesdetails {
  cursor: pointer;
  padding: 0 0 0 18px;
}
.timeicon {
  width: 12%;
  padding: 10px;
  border: 1px solid;
  border-radius: 10px;
  float: left;
  margin-right: 10px;
  background-color: #007c7c;
}
.timerData {
  width: 69px;
  float: left;
}
.skipBtn {
  background: orange;
  min-width: auto;
  padding: 0px 10px;
  margin-right: 10px;
}

.questionNum {
  width: 30%;
  margin-right: 10px;
}

.onlineTest td {
  line-height: 20px;
}
.saveBtn {
  margin: 15px 20px 20px 0px;
  background: rgb(107 106 104 / 58%);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
}
.saveBtns {
  margin-right: 10px;
}
.saveBtn.active {
  background: #089293 !important;
}

#Report label {
  width: 50%;
  float: left;
  text-align: left !important;
}

#Report select {
  width: 50%;
  text-align: left !important;

  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.red {
  color: red;
}

.btn-height {
  height: 50px;
}
.Mui-selected.page-menu-tab {
  padding: 0px 25px;
  background-color: #01327f;
  line-height: 30px;
  color: #fff !important;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
}
.currenttab {
  font-weight: normal !important;
  min-height: auto;
}
span.MuiTabs-indicator.css-1iyle4l-MuiTabs-indicator {
  background: none;
}
.main-bg {
  background-color: #f2f5fc;
  padding: 30px 20px 0 20px;
}
.css-o7d50o {
  padding-top: 70px;
}

.student_bg_folow {
  background-color: #fff;
  padding: 20px 30px;
  border-top: solid 2px #007c7c;
}
.css-heg063-MuiTabs-flexContainer {
  padding-left: 16px;
}
.MuiSelect-select {
  font-size: 14px;
  font-weight: normal;
}
li.MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root.css-1irj9ia-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 14px !important;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-hetbb7-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 14px;
}

button.reportviews {
  font-size: 14px;
  font-weight: normal;
  border-radius: 10px;
}
.action_button {
  margin: 0 auto;
  display: inline-flex;
  padding: 20px 0 0 0;
}
.action_button button {
  margin: 0px 7px;
}
h2.tabheading {
  font-size: 18px;
  font-weight: normal;
  color: #01327f;
}

.imgicondefine img {
  float: left;
  margin: 0px 10px 0px 0px;
}
h2.imgicondefine {
  margin: 0px;
  display: inline-block;
  font-size: 18px;
  color: #01327f;
  font-weight: 500;
  line-height: 47px;
  margin-bottom: 16px;
}

h2.popupHeading {
  margin: 0px;
  display: inline-block;
  font-size: 15px;
  color: #01327f;
  font-weight: 500;
  line-height: 30px;

}

h2.subHeading {
  font-size: 16px !important;
  color: red !important;
}
.progress-bar {
  padding: 0px 0px 0px 9px;
  font-size: 14px;
}
.student-name.title_student {
  font-weight: 500;
  font-size: 14px;
}
.top_tabsetting {
  padding-top: 30px;
  padding-bottom: 10px;
}
.css-13xfq8m-MuiTabPanel-root {
  padding: 0px 0px 0 20px !important;
}



.page-menu-tab {
  background-color: #fff;
  border-radius: 10px;
  margin: 0px 17px 0px 0px;
}

.autoselect span {
  font-size: 14px !important;
  font-weight: normal;
}
.autoselect select {
  font-size: 14px !important;
  font-weight: normal;
}
.viewreportbtn button {
  font-size: 14px;
  font-weight: normal;
  border-radius: 10px;
  width: 160px;
}

.css-e3v2g9-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px;
}
.selectText lable {
  font-size: 14px !important;
}
.css-m8yhyv-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 14px;
}

.smalltext_formate {
  line-height: 13px;
  font-weight: 300;
  margin: 0px;
}
.makeStyles-maxWidth-30 {
  width: 100%;
  margin: 0px 20px !important;
  text-align: left;
}

.makeStyles-widthaccordian-28 {
  width: 25% !important;
}
.css-wei5qg-MuiTableCell-root {
  line-height: 1.5714285714285714;
  font-size: 0.875rem;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(241, 243, 244, 1);
  text-align: center;
  padding: 6px 0px !important;
  color: #212b36;
}
thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root {
  background-color: #eae3e3 !important;
}
thead.MuiTableHead-root.css-15wwp11-MuiTableHead-root th {
  padding: 11px 20px !important;
}
button.viewbtn svg {
  color: #7f7f7f;
}
button.reviewBtn svg {
  color: #089295;
}

button.uoloaddata svg {
  color: #0070c0;
}

button.uploadyoutube svg {
  color: #7e7777;
}

button.updateyoutube svg {
  color: #f30606;
}

button.reportBtn svg {
  color: #002f7f;
}
.date_format_card {
  color: #00357f;
  float: left;
  width: 100%;
  padding: 8px 10px;
  border-bottom: 1px solid #00357f;
}
.date_format_card p {
  float: left;
  display: inline-flex;
  padding: 0px 0 0px 0px;
  font-size: 16px;
  font-weight: 600;
}
.date_format_card p svg {
  margin: 0px 8px 0px 0px;
}

.css-1lu2e92-MuiPaper-root-MuiCard-root {
  border-radius: 6px !important;
}
.makeStyles-margingap-376 {
  border: none !important;
}
.makeStyles-btnchapter-357 {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  background-color: #007c7c !important;
  text-transform: uppercase !important;
  color: #fff !important;
  border-radius: 4px !important;
}
.makeStyles-centericons1-378 {
  padding: 8px;
  background: #007c7c !important;
  text-align: center;
  border-right: solid 1px #ccc !important;
}
.makeStyles-centericons2-379 {
  border: 1px solid #007c7c !important;
  padding: 8px !important;
  background: #007c7c !important;
  text-align: center;
  color: #fff !important;
}

.makeStyles-iconcolor2-46 {
  color: #fff !important;
  padding: 0;
  font-size: 13px;
}
.makeStyles-margingap-51 {
  border: none !important;
}

.makeStyles-aligncentertextMath-75 {
  background: #00357f !important;
}

.makeStyles-btnchapter-32 {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 600;
  background-color: #fff !important;
  text-transform: uppercase !important;
  border-radius: 3px !important;
  /* color: #fff; */
}
.css-a5rdam-MuiGrid-root > .MuiGrid-item {
  padding-top: 3px !important;
}

.css-11bfvty-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
  margin-left: 0px !important;
}

.css-o7d50o {
  padding-bottom: 0px !important;
}

.page-tab {
  margin-top: 22px;
}

.tau-chart__layout {
  overflow-y: scroll !important;
  height: 300px !important;
}

.sepratelineStudents {
  float: left !important;
  width: 20% !important;
}
.makeStyles-bgprofileinner2-52 {
  padding: 20px;
  border-radius: 6px;
  border-top: solid 2px #007c7c;
}
.padding_set_views {
  padding: 20px;
  border-radius: 0px;
  border-top: solid 2px #007c7c;
  background-color: #fff;
  margin: 30px 0 0 0;
}
button.skip_student {
  float: right;
  font-weight: 400;
  border-radius: 10px;
  font-size: 14px;
  padding: 7px 15px;
}
button.submit_btn {
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  padding: 10px 20px;
  margin-right: 10px;
}

.submit_btn_center {
  text-align: center;
  padding: 20px 0 0 0;
}

.heading_modalviewPopup {
  background-color: #01327f;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  padding: 10px 0 10px 20px;
  margin: 0 0 0px 0;
}


h2.heading_modalview {
  background-color: #01327f;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  padding: 14px 0 20px 20px;
  margin: 0 0 15px 0;
}
.popup-heading {
  float: right;
  color: #fff;
  padding: 0px 0 0 0px;
}
.popupContent {
  padding: 0px 20px;
}

.popupContent p table tr td {
  font-size: 13px;
  line-height: 20px;
}

button.cancle_bnt_show {
  text-align: center;
  display: inline-table;
  background-color: #089293;
  color: #ffff;
  font-weight: 000;
  padding: 9px 24px;
  border-radius: 10px;
  margin-bottom: 20px;
}

button.confirm_action_btn {
  text-align: center;
  display: inline-table;
  /* background-color: #089293; */
  color: #333;
  font-weight: 400;
  padding: 9px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: transparent !important;
  border: 1px solid #007c7c !important;

  box-shadow: none;
  font-size: 14px;
}

.btn_wrapper {
  border-top: solid 1px #ccc;
  padding: 15px 0px 0px 0;
  width: 100%;
  text-align: right;
  margin-top: 10px;
}

button.cancle_bnt_show:hover {
  color: #333;
}

input#students {
  font-size: 14px !important;
}

a.skip_student {
  float: right;
  font-weight: 500;
  border-radius: 40px;
  font-size: 14px;
  padding: 10px 30px !important;
  background-color: #007c7c;
  color: #fff;
}
button.next_btn_preview {
  float: right;
  margin: 0 23px 0px 0px;
}

button.submit_btn.btn-print {
  margin-left: 15px;
  background-color: #01327f;
}

.bg_white_upload p {
  font-size: 14px;
}
button.task_btn_close {
  padding: 0px;
  background-color: #000080;
  padding: 6px 10px;
  /* width: 20px; */
  border-radius: 5px;
  margin: 0px 0 0px 7px;
}
.floatleftdiv {
  float: right;
}
.mb-50 {
  margin-bottom: 50px;
}
.mt-18 {
  margin-top: 18px;
}

.sand_clock {
  float: left;
  padding: 2px 5px;
  border-radius: 3px;
  margin: 0 7px 0px 0px;
  float: left;
  display: inline-flex;
  float: right;
}
.time_formet {
  font-weight: 600;
  font-size: 20px;
  padding-top: 10px;
  color: #ff0a0a;
}

.mok_date {
  font-weight: 700;
  color: #007c7c;
  font-size: 17px;
}

h6.select_correct-optons {
  font-size: 16px;
  color: #007c7c;
  text-transform: uppercase;
  padding-top: 15px;
}
.submit_value_btn {
  text-align: right;
}
.skip_qucations {
  background-color: #01327f;
  float: left;
  padding: 6px 14px;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
}
.skipBtns {
  float: left;
  width: 100%;
  padding: 10px 0 0 0;
}
.skipBtns button {
  border-radius: 2px;
  font-size: 16px;
  box-shadow: none;
  padding: 0px 13px;
  font-weight: 500;
  margin-bottom: 5px;
}
.testsep {
  padding-top: 0px;
}

.css-15z7rnx-MuiStack-root {
  margin-bottom: 0px;
}
ul.list_trackviews {
  margin: 0px;
  padding: 0 0 0 14px;
}
ul.list_trackviews li {
  list-style: decimal;
  font-size: 14px;
  line-height: 29px;
}
.instruction_point {
  font-weight: 600;
  color: #007c7c;
  padding: 15px 0 0 0;
  text-transform: uppercase;
  float: left;
  width: 100%;
}

.til_spacepoint {
  padding: 14px 0px 0px 18px !important;
}

.space_point {
  height: 0px;
}

.cover-space {
  padding: 7px 0px 0px 18px !important;
}
.padding_setviews-tracks {
  padding-top: 0px !important;
}

.new_spacetracks hr {
  margin: 0px 0 20px 0 !important;
}
button.outline_put {
  background: none;
  color: #007c7c;
  border: solid 1px #007c7c;
}

button.outline_put:hover {
  color: #fff;
}
button.outline_put:disabled {
  color: #9ca1a1;
  border: solid 1px #b3c1c1;
}
.skip_btn_new {
  text-align: right;
  padding-top: 32px !important;

}
button.height_update {
  line-height: 20px;
}
.align_putright {
  text-align: right;
}
.MuiBox-root.css-cpcvvj {
  padding-bottom: 10px;
}

.onlineTest div {
  line-height: 32px;
}
.onlintest_section {
  font-weight: 700;
  font-size: 16px;
  border-bottom: solid 1px #c6c2c2;
  padding-bottom: 15px;
  line-height: 25px;
}
.onlintest_qlabel {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
  padding: 10px 10px;
  line-height: 20px;
}
.onlinetest_answer ol {
  padding: 1px 20px;
  line-height: 31px;
}
.onlintest_qoption {
  padding-top: 0px;
  padding-left: 15px;
  padding-bottom: 7px;
  line-height: 2;

}

.onlinetest_option {
  line-height: 30px !important;
  font-size: 14px;
  font-weight: 400;
}

.onlinetest_option_correct {
  line-height: 30px !important;
  font-size: 14px;
  font-weight: 600;
  color: #48cd57;
}

table.data_table_inputview tr th {
  text-align: center;
}
tbody.input_datafild tr td {
  text-align: center;
}
tbody.input_datafild tr td div {
  text-align: center !important;
  display: inline-block;
  margin: 0px !important;
}

.uploadRadio lable {
  margin: 0px !important;
}

label.radiobtnLabel {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.subFilter {
  background-color: #fff;
  padding: 0px 50px 30px 30px;
  margin-top: 20px;
}
.student_record {
  padding: 30px 40px 50px 26px;
}

.desktopmain {
    margin-top: 30px;
    margin-left: 15px;
}
a.submit_btn {
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  padding: 10px 20px;
}
.addStuSt {
  margin: 0 0 0 20px;
}
label.class_corse_text {
  padding-top: 12px;
  font-size: 14px;
  font-weight: 500;
}

.main-bgnew {
  background-color: #f2f5fc;
  width: 100%;
  float: left;
}
.container {
  display: inline-flex;
  width: 100%;
}

button.maring_setvidi {
  font-weight: 400;
  border-radius: 10px;
  text-align: center;
  padding: 10px 18px;
  margin: 5px 0 0 13px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otpBtn {
  padding: 25px;
  position: absolute;
  right: 0px;
  border-radius: 0px 8px 8px 0px;
  font-weight: 600;
  height: 52px;
}

/*** Upload data***/

.bg_white_upload {
  background: #fff;
  padding: 20px 15px;

  border: 1px solid #9999991f;
  border-top: solid 2px #007c7c;
}

.backbuttonbg {
  background-color: #000 !important;
  color: #fff;
  font-size: 15px;
  padding: 7px 15px;
  display: inline-block;
  border-radius: 50px;
  font-weight: 400;
  cursor: pointer;
}
.backicon {
  font-size: 15px;
  float: left;
  padding: 0px 0 0 0;
  margin: 3px 0 0 0;
}

.newmargin {
  font-size: 15px;
  float: left;
  padding: 0px 0 0 0;
  margin: 5px 0 0 0;
}

.heading_file {
  font-size: 15px;
  color: #01327f;
  font-weight: 500;
}

.date-icon {
  float: left;
  font-size: 21px;
  margin: 0px 6px 20px 0;
}

.chapter-name-file {
  font-size: 15px;
  color: #01327f;
  font-weight: 500;
  float: left;
  display: inline-flex;
}

.paper_details_icon {
  float: left;
  font-size: 21px;
  margin: 0px 6px 20px 0;
}

.paper-details {
  font-size: 15px;
  color: #01327f;
  font-weight: 500;
  border-bottom: solid 1px #efefef;
  margin: 0px 0px 30px 0;
  padding: 0 0 10px 0;
}

.chapter-icon {
  float: left;
  font-size: 21px;
  margin: 0px 6px 20px 0;
}

ul.chap_list_name {
  margin: -8px 0 0 0;
}
.select_stname {
  background-color: #fff;
}

.result_record {
  font-size: 14px;
  font-weight: 600;
  padding: 7px 13px;
  border-radius: 3px;
  margin-top: 0px;
  color: #fff;
  /* text-transform: uppercase; */
  /* text-decoration: underline; */
  background-color: #01327f;
}
ul.record_list li {
  margin: 0px;
}
.setouter {
  width: 100%;
  float: left;
  padding: 40px 0 0 0px;
}

.css-ccvtsf-MuiTableCell-root {
  padding: 5px 20px;
  color: #212b36;
}
.insttabs .MuiTabs-flexContainer {
  padding-left: 0px !important;
}
.hist_div {
  text-align: right;
}

.padding_plantest {
  padding-top: 18px;
}
button.bnt_pading_views {
  margin: 20px 0 0 0;
}
.box_shadow_none {
  box-shadow: none !important;
}

button.bnt_delect svg {
  background-color: #ff0000;
  color: #fff;
  border-radius: 30px;
  width: 30px;
  height: 30px;
  padding: 0px 4px;
}

.student_basic_details {
  font-size: 14px;
  padding: 5px 0 0 10px;
}
.mCard {
  padding: 0px 0px 10px 0px !important;
}
h6.studetn_name {
  background-color: #eae3e3;
  color: #333;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 14px;
}

.margin_bottom {
  margin-bottom: 20px;
}

.no_padding_set {
  padding: 20px 0px;
}
/* .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
  margin-left: 0px;
} */

.margin_width {
  width: 100% !important;
  margin-left: 0px !important;
}

p.Mui-error {
  color: red !important;
  font-size: 10px;
}

/* .input_field_area input {
    height: 17px;
    font-size: 14px;
    position: relative;
    top: -5px;
}

.input_field_area fieldset {
    top: 0px !important;
  }
  .select_field_area {
    height: 50px;
    position: relative;
    top: -5px;
}

.select_field_area fieldset {
 top: 0px !important;
} */

.bg_white_login {
  background-color: #fff;
  margin-top: 100px;
  padding: 15px 0 20px 0;
  border-radius: 2px;
  border-top: solid 2px #01327f;
  margin-bottom: 30px;
}


.bg_white_login1 {
  background-color: #fff;
  margin-top: 100px;
  padding: 15px 0 20px 0;
  border-radius: 2px;
  border-top: solid 2px #01327f;
  margin-bottom: 30px;
}
h5.top_title_heading {
  text-align: center;
  font-weight: 500;
  font-size: 22px;
}

.chapc_retext {
  height: 53px;
  background-color: #f2f5fc !important;
  border-radius: 4px;
  padding: 0 0 0 5px;
  margin: 0 10px 0px 0px;
  text-align: center;
  font-weight: 600;
}

button.login_front_bg {
  padding: 10px 40px;
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
}
span.agree_trem_text:hover {
  background: none !important;
}

.onlintest_qlabel ol {
  margin: 0 0 20px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.onlinetest_answer p {
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  margin: 0 0 8px 0px;
}

.onlinetest_answer_head {
  float: left;
  width: 100%;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0px;
  padding: 10px 0px 0px 0px;
  display: inline-block;
}
.onlinetest_answer {
  padding: 10px 11px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  background-color: #fcfcfc;
  margin-bottom: 20px;
}

.onlintest_qlabelMain {
  width: 100%;
  margin: 0px;
  padding: 0px;
}
.right_view_anglebtn {
  text-align: right;
  padding: 0 10px 0px 0;
}
h4.review_marging_set {
  margin: 0px;
}

.bg_white_reg {
  background-color: #fff;
  margin-top: 100px;
  padding: 15px 0 20px 0;
  border-radius: 2px;
  border-top: solid 2px #01327f;
  margin-bottom: 30px;
}

.onlintest_qlabelqansMain {
  background-color: #d3caca47;
  padding: 0px 0px;
  float: left;
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
}

.onlinetest_answer_text {
  color: #01327f;
  font-size: 16px;
  font-weight: 500;
}
.onlintest_qlabel_ques {
  background-color: #cccccc12;
  float: left;
  width: 100%;
  padding: 8px 11px;
  font-size: 15px;
  font-weight: 500;
  text-align: justify;
}

.onlintest_explain {
  color: #01327f;
  font-size: 16px;
  font-weight: 500;
}

.onlintest_explain_home {
  width: 100%;
  float: left;
  font-size: 14px;
  background: #fcfcfc;
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.onlintest_qlabel_para.onlintest_qlabel_ques {
  margin-bottom: 20px;
  text-align: justify;
}

label.option_radiobtn span {
  font-size: 14px;
}

p.marks {
  float: right;
  font-size: 14px;
  padding-bottom: 8px;
}

.onlintest_qlabel p {
  line-height: 27px;
  font-size: 14px;
  font-weight: 500;
}
.reg_mobile_views {
  padding: 16px 20px;
}

h6.top_title_heading {
  text-align: center;
  font-weight: 500;
  font-size: 22px;
}
.width_set_regi {
  width: calc(100% + 0px) !important;
}
.termstext span {
  float: left;
  width: 100%;
  padding: 0px 0 15px 0;
}

.auth_pages {
  height: 100% !important;
}
.register_auth {
  height: auto !important;
}

.profile_inner {
  margin-top: 30px !important;
}
.top_div_space {
  padding: 20px 0 10px 0;
}

.deleted_button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.school_icon_buil {
  float: left;
  padding: 0 0px 0px 0px;
  margin: 0 9px 0px 0px;
  color: #01327f;
  font-size: 18px;
}
.mobile_icon_scholl {
  float: left;
  padding: 0 0px 0px 0px;
  margin: 0 9px 0px 0px;
  color: #01327f;
  font-size: 18px;
}

.university_name {
  font-weight: 700;
  letter-spacing: 0px;
  padding: 8px 0 5px 10px;
}

.popup_with_setview {
  width: 100%;
  padding: 0 16px 0px 0px;
}
select.design_select_option {
  height: 47px;
  padding: 0 0px 0px 9px;
  border: solid 1px #ccc;
  border-radius: 6px;
  margin-top: 6px;
}
.border_none {
  border: none;
}
.title_heading_put b {
  font-size: 15px;
  font-weight: 500 !important;
}

.width-set {
  width: 100% !important;
}
.width-set-padding {
  padding-left: 0px !important;
}

span.img_icon_views {
  float: left;
  width: 25px;
  margin: 0px 10px 0px 0px;
}
.carddata {
  border-radius: 0px !important;
  border-top: solid 2px #00357f;
  height: 100%;
}
.carddata h2 {
  font-weight: 500;
  font-size: 20px;
  border-bottom: solid #ccc 1px;
  margin-bottom: 20px;
}

.carddata h6 {
  font-weight: 500;
  font-size: 20px;
  border-bottom: solid #ccc 1px;
  margin-bottom: 20px;
}

p.question-title {
  float: left;
  width: 100%;
}

.carddata p {
  font-size: 15px;
  color: #000;
  margin: 0 0 15px 0;
}
.padding_view_profile {
  padding: 5px 10px !important;
}

span.avtar_icons img {
  width: 22px;
}
span.call_icon img {
  width: 22px;
}
span.pin_code img {
  width: 22px;
  height: 22px;
}
.carddata p:last-child {
  margin: 0 0 0px 0;
}

.Online {
  background-color: #39c93d !important;
}
.Off-line {
  background-color: #818181 !important;
}

.desktop-Online {
  color: #39c93d !important;
}
.desktop-Off-line {
  color: #818181 !important;
}
.main_stud_card {
  border-radius: 5px !important;
  border-top: solid 0px #01327f !important;
}
.style_bnt_div {
  padding: 7px 31px !important;
  /* text-transform: uppercase; */
}
span.add_st_icon img {
  width: 24px;
  float: left;
  margin: 0px 0px -1px 0;
  padding: 5px 0 0 0;
}
span.add_st_icon {
  /* float: left; */
  margin: 0px 10px 0px 0px;
  /* font-size: 0px; */
  /* width: 10%; */
  display: inline-block;
}
span.add_st_icon img {
  position: relative;
  top: 5px;
}
.main_stud_card p {
  float: left;
  width: 100%;
  color: #000;
  font-weight: 400;
  padding-left: 15px;
  font-size: 14px;
}
.space_top {
  margin-top: 16px !important;
  float: left;
  width: 100%;
}
.stuName {
  background-color: #eae3e3;
  padding: 5px 15px;
  font-weight: 500;
  font-size: 16px;
}
/* Parent Side */

.main-page {
  padding: 20px 20px;
}
.main-Container {
  background-color: #fff;
  padding: 20px 30px;
  border-top: solid 2px #01327f;
}
.card_unrounded {
  border-radius: 5px;
}
.mb-15 {
  margin-bottom: 15px;
}
.check_font_form span {
  font-size: 14px;
  font-weight: 500;
}
.space_manage_heading {
  margin-bottom: 24px !important;
  padding-bottom: 0px !important;
}
.padding_left_remove {
  padding-left: 0px;
}
p.margin_remove {
  margin: 0px !important;
}
.margin_remove_set {
  margin: 0px !important;
}
.padding_left_remove {
  padding-left: 0px !important;
}
.margin_top_remove {
  margin: 0px !important;
}

.main-card_box {
  width: 100%;
  /* float: left; */
  /* height: 100%; */
  display: inline-block;
  margin: 0px;
  padding: 0px;
}

.institute_icon {
  float: left;
  width: 30%;
  padding: 0px 20px;
}
.details_institute {
  float: left;
  text-align: center;
  width: 70%;
}

.bnt_center-point {
  text-align: center;
  float: left;
  width: 100%;
}
.institute_icon {
  float: left;
  width: 100px;
  padding: 20px 24px;
  background-color: #01327f;
  border-radius: 59px;
  height: 100px;
  margin: 0 10px 0px 0px;
}

span.moreText.new_margin {
  margin-left: 0px;
}

.space_between {
  height: 10px;
}
.outer_border_box {
  border: solid 1px #bcbcbc;
  box-shadow: none;
  margin: 0 32px 0px 0px;
}
ul.poin-trem_and_conditions li {
  line-height: 30px;
  list-style: none;
}
.margin-set-ref {
  margin-left: 0px;
}

ul.tow_action_btn li {
  display: inline;
}

.institute_address_full {
  float: left;
  width: 85%;
}
.institute_name_main {
  float: left;
  width: 100%;
}
.institute_address_icon {
  float: left;
  width: 36px;
}

span.call_up_icons {
  float: left;
  width: 22px;
  margin: 0px 4px 0px 0px;
}

.accordion {
  margin: 16px 0px 0px 0px !important;
}
ul.btn_set_tracks li {
  display: inline;
  margin: 0 12px 0px 0px;
}

.additional-filter .Mui-expanded {
  margin: 0px 0px !important;
}
.additional-filter {
  min-height: 27px !important;
}
.heading_forment_views {
  text-transform: uppercase;
  color: #007c7c;
  font-weight: 600;
  font-size: 15px;
}
span.forment_heading {
  color: #01327f;
  font-weight: 500;
  font-size: 17px;
}
label.subscription-plan {
  border: solid 1px #ccc;
  padding: 0px 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 6px 1px #cccccc3d;
  margin: 0 20px 0px 0px;
}
.top_space_page {
  margin-top: 20px;
}
.institute_img {
  float: left;
  width: 100px;
  margin: 0px 20px 0px 0px;
}

.img_icon_institute {
  width: 100%;
  text-align: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .modelContentarea {
    width: 90% !important;
  }
  button.set-absel {
    position: absolute;
  }
  
  .action_button {
    display: inline;
  }

  .printshowhide {
    display: none !important;
  }
  .mobileview {
    display: none !important;
  }

  .action_button button {
    margin-bottom: 20px;
    margin-left: 16px;
  }
  .css-1kmluwm-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
    float: left;
    margin: 0;
    text-align: center !important;
  }
  .start_btn_resp {
    text-align: center;
    margin-top: 12px;
  }
  div#Question\ Details div {
    width: 95% !important;
  }

  .saveBtn {
    margin: 5px 4px 5px 0px;
    font-size: 14px;
    min-width: 50px;
  }

  .mb_resposive {
    margin-bottom: 12px;
  }
  .skip_btn_new {
    text-align: center;
  }
  .submit_value_btn {
    text-align: center;
  }
  .next-button_set {
    text-align: center;
  }

  .addStuSt {
    margin-top: 15px;
  }
  .file_create_center {
    text-align: center;
    margin-bottom: 10px;
  }
  .subFilter {
    padding: 0px 20px 20px 20px;
    margin-bottom: 40px;
    margin-left: 0px !important;
    margin-top: 30px;
  }

  label.class_corse_text {
    text-align: center;
  }
  .btn_center {
    text-align: center !important;
  }
  .student_bg_folow {
    margin-left: 0px !important;
  }

  .bg_white_login {
    padding: 15px 20px;
  }

  .bg_white_login1 {
    padding: 15px 20px;
  }

  .chapc_retext {
    height: 51px;
    margin: 0 0px 20px 0px;
  }

  .auth_pages {
    padding: 10px 10px 10px 10px;
  }

  .auth_pages {
    height: auto !important;
  }
  h5.top_title_heading {
    font-size: 20px;
  }

  /* register responsive css here */

  .reg_mobile_views {
    padding: 0px 20px;
  }

  .width_set_regi {
    width: calc(100% + 0px);
  }

  .res_regist_mobile {
    padding-left: 0px !important;
  }
  .profile_inner {
    margin-top: 30px !important;
  }

  .padding_div_view {
    padding-right: 30px !important;
  }
  .padding_div-left {
    padding-left: 0px !important;
  }
  .profile_inner {
    margin-top: 20px !important;
  }
  a.resp_div_student {
    padding: 12px 11px;
    font-size: 13px;
  }
  button.resp_div_student {
    padding: 12px 11px;
    font-size: 13px;
    margin: 15px 20px 0px 0 !important;
  }
  .padding_listviewset {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
  }
  button.view_bnt_div {
    background-color: #007c7c;
    border-radius: 10px !important;
    padding: 10px 30px;
  }
  button.check_answer {
    background-color: #345c96;
    border-radius: 10px !important;
    padding: 14px 10px;
    color: #fff;
  }
  .resp_padding_right {
    padding: 0 32px 0px 0px;
  }

  .details_institute {
    width: 100%;
    padding: 10px 0 0 0;
  }
  .institute_icon img {
    width: 60px;
  }

  .padding_left_remove {
    padding-right: 0px !important;
  }
  .mobile_padding_remove {
    margin-left: 0px;
    padding: 15px 0px 15px 0px !important;
  }
  .accordion {
    margin: 16px 0px 16px 0px !important;
  }

  .img_icon_institute {
    width: 100%;
    text-align: center;
  }
  .main-card_box {
    width: auto;
  }
  .let-csto-bnt {
    text-align: center;
    margin-bottom: 16px;
}
.right_view_anglebtn {
  text-align: center;
}
.align_putright {
  text-align: left;
}
.skip_btn_new {
  padding-top: 0px !important;
}
button.height_update-btn {
  padding: 6px 20px;
}
.track-padding-set input {
  padding: 8px 5px!important;
}
.sand_clock {
  float: left;
}
ul.btn_set_tracks li {
  margin: 0 8px 0px 0px;
}

.view_continer button.submit_btn {
  font-weight: 400;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  font-size: 0.7rem;
}

}

@media only screen and (min-device-width: 481px) and (max-device-width: 767px) {
    .main-bg {
        padding: 30px 20px 0 20px;
      }
  .printshowhide {
    display: none !important;
  }
  .mobileview {
    display: none !important;
  }
  .modelContentarea {
    width: 80% !important;
  }
  .action_button button {
    margin: 0px 8px 0px 0px;
    width: 210px;
  }
  .css-1kmluwm-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
    float: left;
    margin: 0;
    text-align: center !important;
  }

  .start_btn_resp {
    text-align: center;
    margin-top: 12px;
  }
  div#Question\ Details div {
    width: 95% !important;
  }
  .saveBtn {
    margin: 20px 4px 16px 0px;
    font-size: 14px;
  }

  .mb_resposive {
    margin-bottom: 12px;
  }
  .skip_btn_new {
    text-align: center;
    padding-top: 32px !important;
  }
  .submit_value_btn {
    text-align: center;
  }

  .next-button_set {
    text-align: center;
  }

  .no_padding_set {
    padding: 20px 0px;
  }
  .addStuSt {
    margin-top: 15px;
  }
  .file_create_center {
    text-align: center;
    margin-bottom: 10px;
  }
  .subFilter {
    padding: 0px 20px 20px 20px;
    margin-bottom: 40px;
  }

  label.class_corse_text {
    text-align: left;
  }

  .btn_center {
    text-align: center !important;
  }
  .reg_mobile_views {
    padding: 0px 20px;
  }
  .auth_pages {
    height: auto !important;
  }
  .profile_inner {
    margin-top: 30px !important;
  }

  .padding_div_view {
    padding-right: 30px !important;
  }
  .add_status_resp {
    margin: 15px 0 0 0;
  }
  .subFilter {
    padding: 0px 20px 20px 20px;
    margin-bottom: 40px;
    margin-left: 0px !important;
    margin-top: 30px;
  }
  .padding_div-left {
    padding-left: 0px !important;
  }
  .mobile_padding_remove {
    margin-left: 0px;
    padding: 15px 0px 15px 0px !important;
  }
  .img_icon_institute {
    width: 100%;
    text-align: center;
  }
  .main-card_box {
    width: auto;
  }
  .align_putright {
    text-align: right;
}
button.height_update-btn {
  padding: 6px 20px;
}
.track-padding-set input {
  padding: 8px 5px!important;
}
.sand_clock {
 float: left;
}

.view_continer button.submit_btn {
  font-weight: 400;
  border-radius: 10px;
  padding: 5px 15px;
  margin-bottom: 10px;
  font-size: 1 rem;
}
}

@media only screen and (min-device-width: 768px) and (max-device-width: 900px) {
  .printshowhide {
    display: none !important;
  }
  .mobileview {
    display: none !important;
  }
  .action_button button {
    margin: 0px 8px 0px 0px;
    width: 210px;
  }
  .action_button {
    margin: 0 auto;
    display: inline-flex;
    padding: 20px 0 0 0;
  }
  .css-1kmluwm-MuiGrid-root {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
    float: left;
    margin: 0;
    text-align: center !important;
  }

  .addStuSt {
    margin-top: 15px;
  }
  .file_create_center {
    text-align: center;
    margin-bottom: 10px;
  }
  .subFilter {
    padding: 0px 20px 20px 20px;
    margin-bottom: 40px;
    margin-top: 30px;
  }

  label.class_corse_text {
    text-align: left;
  }
  .btn_center {
    text-align: center;
  }

  .reg_mobile_views {
    padding: 0px 20px;
  }
  .auth_pages {
    height: auto !important;
  }
  .profile_inner {
    margin-top: 30px !important;
  }
  .padding_div-left {
    padding-left: 0px !important;
  }
  ul.tow_action_btn li {
    margin: 0px 10px 0px 10px;
  }
  .details_institute {
    width: auto;
  }
  button.set-absel {
    position: unset!important;
}

}

@media only screen and (min-device-width: 901px) and (max-device-width: 1024px) {
  .printshowhide {
    display: none !important;
  }

  .action_button button {
    margin: 0px 8px 0px 0px;
    width: 210px;
  }
  .action_button {
    margin: 0 auto;
    display: inline-flex;
    padding: 20px 0 0 0;
  }
  button.resp_div_student {
    margin-left: 5px;
  }
  ul.tow_action_btn li {
    margin: 0px 10px 0px 10px;
  }
  .details_institute {
    width: auto;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 702px) {
  .printshowhide {
    display: none;
  }
  .mobileview {
    display: none !important;
  }
  .main-bg {
    padding: 30px 20px 0 20px;
  }

  .skip_btn_new {
    text-align: right;
    padding-top: 32px !important;
  }
  .saveBtn {
    min-width: 55px;
  }
  .add_status_resp {
    margin: 0px 0 0 0;
  }
  .top_div_space {
    padding: 20px 0 10px 0;
  }

  .resp_padding_right {
    padding: 0 32px 0px 0px;
  }

  .details_institute {
    width: 100%;
  }
  ul.tow_action_btn li {
    margin: 0px 10px 0px 10px;
  }
  .accordion {
    margin: 16px 0px 0px 0px !important;
  }
  .img_icon_institute {
    width: 100%;
    text-align: center;
  }
  .main-card_box {
    width: auto;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 600px) {
  /* login responsive css here */
  .printshowhide {
    display: none;
  }
  .mobileview {
    display: none !important;
  }
  .bg_white_login {
    padding: 15px 20px;
  }

  .bg_white_login1 {
    padding: 15px 20px;
  }

  .chapc_retext {
    height: 51px;
    margin: 0 0px 20px 0px;
  }

  /* .main-bgnew {
    padding: 10px 10px 10px 10px;
  } */
  h5.top_title_heading {
    font-size: 20px;
  }

  /* register responsive css here */

  .reg_mobile_views {
    padding: 0px 20px;
  }

  .width_set_regi {
    width: calc(100% + 0px);
  }

  .res_regist_mobile {
    padding-left: 0px !important;
  }
  .student_record {
    padding: 80px 40px 50px 26px;
  }
  .profile_inner {
    margin-top: 60px !important;
  }
  .padding_listviewset {
    padding: 10px 20px;
    width: 100%;
    text-align: center;
  }
  button.view_bnt_div {
    background-color: #007c7c;
    border-radius: 10px !important;
    padding: 10px 30px;
  }
  button.check_answer {
    background-color: #345c96;
    border-radius: 10px !important;
    padding: 14px 10px;
    color: #fff;
  }
  .resp_padding_right {
    padding: 0 32px 0px 0px;
  }
  .details_institute {
    width: 100%;
  }
  ul.tow_action_btn li {
    margin: 0px 10px 0px 10px;
  }
  .accordion {
    margin: 16px 0px 16px 0px !important;
  }
  .img_icon_institute {
    width: 100%;
    text-align: center;
  }
  .main-card_box {
    width: auto;
  }
  .let-csto-bnt {
    text-align: center;
    margin-bottom: 16px;
}
.right_view_anglebtn {
  text-align: center;
}
.align_putright {
  text-align: left;
}.skip_btn_new {
  padding-top: 0px !important;
}
.align_putright {
  text-align: right;
}
.skip_btn_new {
  text-align: center;
}
}

@media only screen and (min-device-width: 703px) and (max-device-width: 1024px) {
  .add_status_resp {
    margin: 0px 0 0 0;
  }
  .top_div_space {
    padding: 20px 0 10px 0;
  }

  ul.tow_action_btn li {
    margin: 0px 0px 0px 0px;
}
  .details_institute {
    width: auto;
  }
}

/* Loader */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(150, 141, 141, 0.856);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.5s;
  z-index: 9999;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #007c7c;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
img.whasappicon {
  width: 25px;
  padding: 2px;
}
span.display-inline {
  align-content: center;
  font-size: 12px;
}
span.insti_img_views img {
  padding-top: 22px;
}
.pl-5{
margin-left: 5px;
}

.chpterPopup li {
    width: fit-content;
    display: inline-flex;
    margin: 5px;
}

.mt-5 {
  margin-top: 30px;
}

.popup_with_setview {
  width: 100%;
  padding: 0px 16px 0px 20px;
}

.res_regist_mobile.padd-space {
  padding-bottom: 15px;
}
.Correct {
  color: #fff !important;
  background: #007c7c !important;
}

.Incorrect {
  color: #fff !important;
  background: #fe0000 !important;
}
.Partially_Correct  {
  color: #fff !important;
  background: #febf00 !important;
}

  .Did_Not_Attempt  {
    color: #fff !important;
    background: #808080 !important;
}ul.bnt-nex-pre li {
  list-style: none;
  display: inline;
}ul.bnt-nex-pre {
  margin: 0px;
  padding: 0px;
}
ul.bnt-nex-pre li button {
  margin: 0px 0px 0px 20px !important;
}

@media print {
  table.option {
    margin: 10px;
    margin-left: 4%;
    font-size: 14px;
    float: left;
    width: 400px!important;
}
}


.track-padding-set input {
  padding: 10px 5px;
}
.sand_clock img {
  position: relative;
  top: 7px;
  left: -5px;
}

.preferences div {
  font-size: 18px;
  font-weight: normal;
  color: #01327f;
}

.form-control-label {
  display: flex;
  align-items: flex-start;
  
}

span.label-class {
  font-size: 14px;
  position: relative;
  
}

.heading {
  padding: 0 0 10px 0;
  font-weight: 500;
}

.question-container {
  margin-bottom: 20px;
}

.question-container label,.question-container label span {
  font-size: 14px !important;
}



.options-container {
  margin-left: 40px; /* Indent options under the question */
}

.options-container ol, .options-container div, .form-control-label span div, .form-control-label div ol li {
  padding-left: 20px !important;
  font-size: 14px !important;
}

.options-container div {
 width:50%;
 float: left;
 padding-bottom: 5px;
}

.option-class {
  display: block; /* Make each option appear on a new line */
  margin-bottom: 5px;
  font-size: 14px;
  color: #000;
}
.view_continer button img{
  padding-right: 5px;
}